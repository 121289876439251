export enum SearchCode {
  CE = "CE",
  SKU = "SKU",
}

export enum View {
  All = "all",
  Personal = "personal",
  NeedLicence = "needLicense",
  Advertising = "advertising",
  Repair = "repair",
  NewProducts = "newProducts",
  ForLicenses = "forLicenses",
  BlankAdditions = "blankAdditions",
}
export enum CounterpartyView {
  List = "list",
  Groups = "groups",
  ForLicenses = "forLicenses",
}
export enum OneTimePricesView {
  Actual = "Actual",
  Archive = "Archive",
}
export enum OneTimePricesType {
  All = "All",
  Assigned = "Assigned",
  NotAssigned = "Not assigned",
}
export enum Sorting {
  ASC = "asc",
  DESC = "desc",
  DEFAULT = "",
}
export enum Notification {
  SUCCESS = "success",
  ERROR = "danger",
  INFO = "info",
  DEFAULT = "default",
  WARNING = "warning",
}
export enum Currency {
  USD = "usd",
  GBP = "gbp",
}
export enum CurrencyId {
  EUR = 3,
  BYN = 7,
}
export enum Period {
  LastMonth = "LastMonth",
  LastThreeMonth = "LastThreeMonth",
  LastSixMonth = "LastSixMonth",
  LastYear = "LastYear",
  Yesterday = "Yesterday",
  Tomorrow = "Tomorrow",
  Today = "Today",
  All = "All",
  Range = "Range",
  Period = "Period",
}

export enum CommandbarCommands {
  Add,
  Display,
  Edit,
  EditDate,
  SetNote,
  ReplyDate,
  Delete,
  DeleteCounterParty,
  DeleteAddition,
  Prices,
  PriceList,
  OrderForms,
  OrderFormsSpareParts,
  Download,
  DownloadExcel,
  DownloadExcelExtra,
  Upload,
  UploadPrices,
  UploadFromProduction,
  Copy,
  Unite,
  Devices,
  ConfirmUnlock,
  ConfirmForceUnlock,
  InvoiceBeltex,
  InvoiceYukon,
  InvoicePolaris,
  InvoicePulsar,
  InvoiceBelRubles,
  Tags,
  InvoicePrinting,
  PackingList,
  CustCodesBy,
  CustCodesLv,
  CustCodesLt,
  CustCodesUk,
  Move,
  AddGroup,
  AddList,
  AddCounterparty,
  EditCounterparty,
  Counterparties,
  Agreements,
  Forecast,
  EditSalesResponsibles,
  EditServiceResponsibles,
  AssignReplacement,
  DeleteReplacement,
  Licenses,
  Approve,
  CheckList,
  Close,
  State,
  History,
  TempImport,
  Details,
  Extra,
  Accept,
  Postpone,
  Print,
  PrintSales,
  PrintWarehouse,
  Period,
  Clear,
  Replication,
  DownloadOrderForm,
  DownloadDebt,
  DownloadPricelist,
  Check,
  SetDistributorNote,
  ConfirmClosing,
  CreatePaymentForBeltex,
  Open,
  ConfirmOpening,
  Error,
  Reset,
  Generate,
  Payments,
  Balance,
  BuhLV,
  Portal,
  Debt,
  Reject,
  Info,
  Email,
  Finvalda,
  FinvaldaConfirm,
  EditFields,
  OnPortal,
  ItemsInLicenses,
  SendToWork,
  ChangeOrderStatus,
  ChangeModification,
  ChangeSeller,
  CheckResponsible,
  Parametrs,
  CheckLicenses,
  CheckPrices,
  CheckExternalPrices,
  Calculate,
  BackOrder,
  ApproveOneTimePrices,
  CancelApproval,
  Excel,
  Fill,
  MinimumValue,
  MaximumValue,
  NormativeValue,
  Notification,
  Send,
  Save,
  EditRates,
  SetRecipient,
  SetDate,
  DisplayColumns,
  ExcelBeltex,
  Confirm,
  Permissions,
  PermissionsGroup,
  GroupsOfUser,
  Transkcript,
  RoundPrices,
  Search,
  SearchPanel,
  TurnOnControlMode,
  RemovePbo,
  EditControlModeShipment,
  EditShipment,
  CreateRequests,
  RegionTranscription,
  ProductTranscription,
  Groups,
  Unlock,
  UploadRequest,
  InvoicePulsarPl,
  UpdateOnPortal,
  CloseBackOrder,
  Deactivate,
  StockBalance,
  ShipmentsDisplay,
  Subscriptions,
  EditProforma,
  None,
}
export enum PanelTab {
  Main,
  Other,
}
export enum OrderFormsTab {
  Add = "add",
  Edit = "edit",
  Delete = "delete",
}
export enum AppLanguage {
  English = "en",
  Russian = "ru",
}
export enum Companies {
  PulsarLVId = 1939,
  MezonId = 60,
  PolarisId = 346,
  YukonId = 26,
  BeltexId = 25,
  OPYU = 92,
  PulsarPLId = 2049,
}
export enum OneTimePrices {
  Apply = "Apply",
  Ingore = "Ignore",
  None = "None",
}

export enum DistributorOrderStatus {
  Draft = "Draft",
  Sent = "Sent",
  Received = "Received",
  Accepted = "Accepted",
  Closed = "Closed",
  Rejected = "Rejected",
  SentManually = "SentManually",
  SentFromFile = "SentFromFile",
  ReceivedPlus = "ReceivedPlus",
  Unknown = "Unknown",
}

export enum Keydown {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Point = ".",
  F3 = "F3",
  Tab = "Tab",
  Enter = "Enter",
}

export enum Lcid {
  Ru = 1049,
  En = 1033,
  Lt = 1063,
}

export enum PriceKind {
  Actual = "Actual",
  Last = "Last",
}

export enum RequestFlag {
  Yes = "Yes",
  No = "No",
  Undefined = "Undefined",
}

export enum ShipmentsStatus {
  Closed = "closed",
  Opened = "opened",
  All = "all",
}

export enum SelectionCounterparties {
  All = "All",
  ExternalOnly = "ExternalOnly",
  InternalOnly = "InternalOnly",
}

export enum SelectionCounterpartyType {
  All = "",
  Group = "*",
  Counterparty = "=",
}

export enum PortalView {
  OnPortal = "Yes",
  RemoveFromPortal = "No",
  Undefined = "Undefined",
}

export enum SignalRNotificationType {
  ShipmentLocked = "Shipment.Locked",
  ShipmentUnlocked = "Shipment.Unlocked",
  ShipmentClosed = "Shipment.Closed",
  ShipmentOpened = "Shipment.Opened",
  ShipmentExportedToProduction = "Shipment.ExportedToProduction",
  ShipmentExportedToFinvalda = "Shipment.ExportedToFinvalda",
  ShipmentEmailSent = "Shipment.EmailSent",
  ShipmentShowOnPortalChanged = "Shipment.ShowOnPortalChanged",
  ShipmentsDeleted = "Shipments.Deleted",
}

export enum ShipmentEventType {
  All = "All",
  ShipmentOpened = "ShipmentOpened",
  ShipmentClosed = "ShipmentClosed",
  ShipmentDeleted = "ShipmentDeleted",
  ShipmentCreated = "ShipmentCreated",
  ShipmentCreatedFromExcel = "ShipmentCreatedFromExcel",
  ShipmentCopied = "ShipmentCopied",
  ShipmentMerged = "ShipmentMerged",
  ShipmentDevicesAdded = "ShipmentDevicesAdded",
  ShipmentDevicesDeleted = "ShipmentDevicesDeleted",
  ShipmentRequestLoaded = "ShipmentRequestLoaded",
  ShipmentItemsCountryOriginChanged = "ShipmentItemsCountryOriginChanged",
  ShipmentSwitchedToControlMode = "ShipmentSwitchedToControlMode",
  ShipmentRequestPOSLoaded = "ShipmentRequestPOSLoaded",
  CreatedFromSchedule = "CreatedFromSchedule"
}

export enum DeviceEventType {
  DeviceCustomsCodeChanged = "DeviceCustomsCodeChanged",
  DeviceNeedLicenseChanged = "DeviceNeedLicenseChanged",
}

export enum CounterpartyEventType {
  CounterpartyAdded = "CounterpartyAdded",
  CounterpartyMoved = "CounterpartyMoved",
  GroupAdded = "GroupAdded",
  CounterpartyPricelistsChanged = "CounterpartyPricelistsChanged",
  SalesResponsibleWorkerChanged = "SalesResponsibleWorkerChanged",
  ServiceResponsibleWorkerChanged = "ServiceResponsibleWorkerChanged",
}

export enum IntrastatCustomCodeEventType {
  Added = "Added",
  Changed = "Changed",
  Deleted = "Deleted",
}

export enum NotificationStatus {
  All = "",
  Sent = "Sent",
  NotSent = "NotSent",
}

export enum MoveDirection {
  Up = "Up",
  Down = "Down",
}

export enum BadOperations {
  BlankAdditionAlreadyExist = "BlankAdditionAlreadyExist",
  OpenContractExists = "OpenContractExists",
  ShipmentExists = "ShipmentExists",
  BrandIsUnderUsage = "BrandIsUnderUsage",
  CategoryIsUnderUsage = "CategoryIsUnderUsage",
  GroupHasChildren = "GroupHasChildren",
  GroupIsUnderUsage = "GroupIsUnderUsage",
  SubgroupIsUnderUsage = "SubgroupIsUnderUsage",
  PricelistNameIsUnderUsage = "PricelistNameIsUnderUsage",
  CounterpartyHasShipment = "CounterpartyHasShipment",
  CounterpartyHasContract = "CounterpartyHasContract",
  CounterpartyHasPayment = "CounterpartyHasPayment",
  CounterpartyHasOrder = "CounterpartyHasOrder",
  CounterpartyHasNoCurrencyAssigned = "CounterpartyHasNoCurrencyAssigned",
  AgreementAlreadyExists = "BadOperation_AgreementAlreadyExists",
  ForecastAlreadyExistForSelectedDates = "ForecastAlreadyExistForSelectedDates",
  CurrencyAlreadyExists = "CurrencyAlreadyExists",
  CurrencyIsUnderUsage = "CurrencyIsUnderUsage",
  DeviceProductGroupIsUnderUsage = "DeviceProductGroupIsUnderUsage",
  ResponsibleWorkerIsUnderUsage = "ResponsibleWorkerIsUnderUsage",
  ShipmentClosed = "BadOperation_ShipmentClosed",
  ShipmentLocked = "BadOperation_ShipmentLocked",
  DocumentNotFound = "DocumentNotFound",
  MissingDevices = "MissingDevices",
  LicenseHistoryExist = "BadOperation_LicenseHistoryExist",
  OneTimePricesExist = "BadOperation_OneTimePricesExist",
  LockedShipmentFound = "BadOperation_LockedShipmentFound",
  ShipmentNotLocked = "BadOperation_ShipmentNotLocked",
  SerialNumbersExist = "BadOperation_SerialNumbersExist",
  OpenPricesExist = "BadOperation_OpenPricesExist",
  NoPriceAssigned = "BadOperation_NoPriceAssigned",
  BadOperation_ItemNotFound = "BadOperation_ItemNotFound",
  NoCurrencyAssigned = "NoCurrencyAssigned",
  NoShipmentItems = "NoShipmentItems",
  MissingContracts = "MissingContracts",
  MissingPackingInfo = "MissingPackingInfo",
  WrongShipper = "WrongShipper",
  PulsarNotShipAnything = "PulsarNotShipAnything",
  ShipmentItemsNotListedAsRex = "ShipmentItemsNotListedAsRex",
  YukonNotShipAnything = "YukonNotShipAnything",
  PolarisNotShipAnything = "PolarisNotShipAnything",
  NoBoxes = "NoBoxes",
  TermOfDeliveryIsUnderUsage = "TermOfDeliveryIsUnderUsage",
  CountryIsUnderUsage = "CountryIsUnderUsage",
  ExchangeRatePeriodAlreadyExists = "ExchangeRatePeriodAlreadyExists",
  DuplicateFound = "DuplicateFound",
  ResponsibleWorkerReplacementAlreadyExists = "ResponsibleWorkerReplacementAlreadyExists",
  ParentGroupNotFound = "ParentGroupNotFound",
  BadGroupLevel = "BadGroupLevel",
  GroupNotFound = "GroupNotFound",
  BadGroup = "BadGroup",
  ShipmentHasNoShippingDate = "ShipmentHasNoShippingDate",
  SignatureIsUnderUsage = "SignatureIsUnderUsage",
  CurrencyNotFound = "CurrencyNotFound",
  CompanyNotFound = "CompanyNotFound",
  PricelistNameNotFound = "PricelistNameNotFound",
  PricelistConditionNotFound = "PricelistConditionNotFound",
  CounterpartyNotFound = "CounterpartyNotFound",
  PricelistUnderUsage = "PricelistUnderUsage",
  DevicesNotFound = "DevicesNotFound",
  DeviceAlreadyExists = "DeviceAlreadyExists",
  NoPricesFoundForApproval = "NoPricesFoundForApproval",
  LicenseListIsUnderUsage = "LicenseListIsUnderUsage",
  PricelistNotFound = "PricelistNotFound",
  AnotherOwner = "AnotherOwner",
  LicenseListNotFound = "LicenseListNotFound",
  DeviceNotFound = "DeviceNotFound",
  LicenseListItemAlreadyExist = "LicenseListItemAlreadyExist",
  DuplicatedCode = "DuplicatedCode",
  AlreadyExists = "AlreadyExists",
  IsUnderUsage = "IsUnderUsage",
  PeriodArleadyExists = "PeriodArleadyExists",
  ForecastPeriodNotFound = "ForecastPeriodNotFound",
  ForecastPriceAlreadyExist = "ForecastPriceAlreadyExist",
  LicenseСlosedOrApproved = "LicenseСlosedOrApproved",
  LicenseNotFound = "LicenseNotFound",
  LicenseListItemIsShipped = "LicenseListItemIsShipped",
  LicenseItemsInvalidOrder = "LicenseItemsInvalidOrder",
  DuplicatedRecord = "DuplicatedRecord",
  EmptyCounterpartyName = "EmptyCounterpartyName",
  CountepartyNotFound = "CountepartyNotFound",
  EmptyShipperName = "EmptyShipperName",
  InvalidShipperName = "InvalidShipperName",
  EmptySek = "EmptySek",
  InvalidSek = "InvalidSek",
  DuplicatedRecordByDevice = "DuplicatedRecordByDevice",
  InvalidQuantity = "InvalidQuantity",
  NegativeQuantity = "NegativeQuantity",
  LicenseDeviceAlreadyExist = "LicenseDeviceAlreadyExist",
  LicenseGroupAlreadyExist = "LicenseGroupAlreadyExist",
  NoPricesForApproval = "NoPricesForApproval",
  ListNotFound = "ListNotFound",
  LicenseListAlreadyExist = "LicenseListAlreadyExist",
  CertifiedDeviceAlreadyExists = "CertifiedDeviceAlreadyExists",
  DocumentHasNoDevices = "DocumentHasNoDevices",
  ContractProhibitedForDeletion = "ContractProhibitedForDeletion",
  ContractMustBeDraft = "ContractMustBeDraft",
  ContractMustBeNumbered = "ContractMustBeNumbered",
  ContractHasEmptyPrices = "ContractHasEmptyPrices",
  ContractMustBeApproved = "ContractMustBeApproved",
  ContractProhibitedForChanges = "ContractProhibitedForChanges",
  ContractMissingDevices = "ContractMissingDevices",
  ContractNotFound = "ContractNotFound",
  ContractDeviceAlreadyExists = "ContractDeviceAlreadyExists",
  ContractHistoryHasEmptyPrices = "ContractHistoryHasEmptyPrices",
  ContractHistoryHasUnderprices = "ContractHistoryHasUnderprices",
  ContractDevicesNotFound = "ContractDevicesNotFound",
  DocumentHasNoRecords = "DocumentHasNoRecords",
  ContractHistoryHasNoDelayedChanges = "ContractHistoryHasNoDelayedChanges",
  ContractAlreadyExists = "ContractAlreadyExists",
  BlankIsUnderUsage = "BlankIsUnderUsage",
  BlanksNotFound = "BlanksNotFound",
  BlankPeriodNotFound = "BlankPeriodNotFound",
  BlankNotFound = "BlankNotFound",
  BlankNameExist = "BlankNameExist",
  OrderAlreadyExist = "OrderAlreadyExist",
  ListNameNotUnique = "ListNameNotUnique",
  NoAcceptableDepartment = "NoAcceptableDepartment",
  DeviceSpecialPriceAllreadyExists = "DeviceSpecialPriceAllreadyExists",
  DeletionIsDisallowed = "DeletionIsDisallowed",
  CreditorHasNoCurrencyAssigned = "CreditorHasNoCurrencyAssigned",
  ShimpentNotFound = "ShimpentNotFound",
  SerialNumbersNotFound = "SerialNumbersNotFound",
  LicensesNotAssignedToDevices = "LicensesNotAssignedToDevices",
  ShipmentForBeltexEmployee = "ShipmentForBeltexEmployee",
  BadOperation_ShipmentClosed = "BadOperation_ShipmentClosed",
  BadOperation_ShipmentLocked = "BadOperation_ShipmentLocked",
  ShipmentHasNoDate = "ShipmentHasNoDate",
  ShipmentHasNoCurrency = "ShipmentHasNoCurrency",
  BadOperation_ContractNotFound = "BadOperation_ContractNotFound",
  ShipmentHasConnectedPayments = "ShipmentHasConnectedPayments",
  BadOperation_ShipmentNotClosed = "BadOperation_ShipmentNotClosed",
  CertificatesNotApplicable = "CertificatesNotApplicable",
  NonCertifiedDevicesNotFound = "NonCertifiedDevicesNotFound",
  NoLicensedItems = "NoLicensedItems",
  ShipmentRequestExists = "ShipmentRequestExists",
  InvoiceNotFound = "InvoiceNotFound",
  AllDevicesHaveLicense = "AllDevicesHaveLicense",
  NoLicensedDevices = "NoLicensedDevices",
  NoLicensedDevicesInShipment = "NoLicensedDevicesInShipment",
  ShipmentDoesNotRequireLicense = "ShipmentDoesNotRequireLicense",
  NoGBPExchangeOnShippingDate = "NoGBPExchangeOnShippingDate",
  NoValidGBILicense = "NoValidGBILicense",
  NoValidLTILicense = "NoValidLTILicense",
  NoValidGBOLicense = "NoValidGBOLicense",
  NoValidLVILicense = "NoValidLVILicense",
  NoValidBOILicense = "NoValidBOILicense",
  ThereAreSomeDevicesThatCannotBeShipped = "ThereAreSomeDevicesThatCannotBeShipped",
  LicenseNoCurrencyAssigned = "BadOperation_NoCurrencyAssigned",
  NewQuantityIsLessThanShipped = "NewQuantityIsLessThanShipped",
  MissingOrders = "MissingOrders",
  WrongOrderStatus = "WrongOrderStatus",
  OrderNotFound = "OrderNotFound",
  ContractDeviceIsOpenedAlready = "ContractDeviceIsOpenedAlready",
  ContractDeviceContainedInAddition = "ContractDeviceContainedInAddition",
  InvalidOrderDates = "InvalidOrderDates",
  NoDebts = "NoDebts",
  MissingWorkNumbers = "MissingWorkNumbers",
  ResourceNotFound = "ResourceNotFound",
  ShipmentHasNoItems = "ShipmentHasNoItems",
  FinvaldaDependency = "FinvaldaDependency",
  FinvaldaWrapperDependencyError = "FinvaldaWrapperDependencyError",
  ExternalDependency = "ExternalDependency",
  ShipmentAlreadyImported = "ShipmentAlreadyImported",
  ShipmentNotForExport = "ShipmentNotForExport",
  LicenseHistoryNotFound = "LicenseHistoryNotFound",
  CounterpartyHasShipmentRequest = "CounterpartyHasShipmentRequest",
  InvalidFileFormat = "InvalidFileFormat",
  ShipmentDevicePackedMoreThanQty = "ShipmentDevicePackedMoreThanQty",
  ShipmentDevicePacked = "ShipmentDevicePacked",
  ShipmentDeviceInPackList = "ShipmentDeviceInPackList",
  ShipmentItemAlreadyExist = "BadOperation_ShipmentItemAlreadyExist",
  NotCheckedItems = "NotCheckedItems",
  MainPeriodIsNotOpen = "MainPeriodIsNotOpen",
  InvalidPeriodSelected = "InvalidPeriodSelected",
  OrderHasDevicesWithoutPackaging = "OrderHasDevicesWithoutPackaging",
  ChangeOrderStatusPrompt = "ChangeOrderStatusPrompt",
  OrderMissingDevices = "OrderMissingDevices",
  BadConfirmQty = "BadConfirmQty",
  BadDeviceStatus = "BadDeviceStatus",
  ShipmentIdMissing = "ShipmentIdMissing",
  RegionIsUnderUsage = "RegionIsUnderUsage",
  NoLicensedOrderItems = "NoLicensedOrderItems",
  DevicesNotFoundInCatalog = "DevicesNotFoundInCatalog",
  ProductionWrapperDependencyError = "ProductionWrapperDependencyError",
  OrderHasNoDevicesForConfirmation = "OrderHasNoDevicesForConfirmation",
  BadOrderStatus = "BadOrderStatus",
  ShipmentNotFound = "ShipmentNotFound",
  RequestNotFound = "RequestNotFound",
  NoLicensedRequestItems = "NoLicensedRequestItems",
  OnlyBeltexAllowed = "OnlyBeltexAllowed",
  ShipmentRequestNotFound = "ShipmentRequestNotFound",
  ShipmentDisabled = "ShipmentDisabled",
  ShipmentRequestHasNoDevices = "ShipmentRequestHasNoDevices",
  IndicatorsAlreadyExist = "IndicatorsAlreadyExist",
  PreviousYearIndicatorsNotExist = "PreviousYearIndicatorsNotExist",
  NoDevicesInOrder = "NoDevicesInOrder",
  MissingRequests = "MissingRequests",
  NotInternalShipment = "NotInternalShipment",
  CounterpartyLineNotFound = "CounterpartyLineNotFound",
  DateLineNotFound = "DateLineNotFound",
  InvalidPrice = "InvalidPrice",
  NegativePrice = "NegativePrice",
  InvalidPricelistId = "InvalidPricelistId",
  ProductQuotaCounterpartyAlreadyExist = "ProductQuotaCounterpartyAlreadyExist",
  UserFromOtherDepartment = "UserFromOtherDepartment",
  PriceCalculationAlreadyApproved = "PriceCalculationAlreadyApproved",
  OneTimePricesExistForPriceCalcluation = "OneTimePricesExistForPriceCalcluation",
  NotAllowedCurrencyNames = "NotAllowedCurrencyNames",
  PriceCalculationHasAssignedOneTimePrices = "PriceCalculationHasAssignedOneTimePrices",
  PricelistNotFoundForCurrency = "PricelistNotFoundForCurrency",
  PricelistForOtherCompany = "PricelistForOtherCompany",
  PricelistForOtherCurrency = "PricelistForOtherCurrency",
  PriceCalculationNotApproved = "PriceCalculationNotApproved",
  BlankAdditionHaveNoChanges = "BlankAdditionHaveNoChanges",
  NoPricesForConditions = "NoPricesForConditions",
  DepartmentNotFound = "DepartmentNotFound",
  ProductQuotaCounterpartyNotFound = "ProductQuotaCounterpartyNotFound",
  ProductQuotaGroupNotFound = "ProductQuotaGroupNotFound",
  ProductQuotaCounterpartyQuotaAlreadyExist = "ProductQuotaCounterpartyQuotaAlreadyExist",
  DevicesWithZeroCostNotFound = "DevicesWithZeroCostNotFound",
  BadCompany = "BadCompany",
  BadCurrency = "BadCurrency",
  ProductQuotaGroupIsInUse = "ProductQuotaGroupIsInUse",
  GroupDeviceGroupAlreadyExist = "GroupDeviceGroupAlreadyExist",
  GroupDeviceAlreadyExist = "GroupDeviceAlreadyExist",
  DeviceNotAvailable = "DeviceNotAvailable",
  PriceCalculationHasNoDevices = "PriceCalculationHasNoDevices",
  PriceCalculationNotFound = "PriceCalculationNotFound",
  InvalidPrimeCurrency = "InvalidPrimeCurrency",
  PeriodDoesNotExist = "PeriodDoesNotExist",
  RexListNotFound = "RexListNotFound",
  ShipmentExportNotAllowedForUser = "ShipmentExportNotAllowedForUser",
  EmailAlreadyExist = "EmailAlreadyExist",
  BlankHasItems = "BlankHasItems",
  MissingPricelists = "MissingPricelists",
  SecurityGroupAlreadyExists = "SecurityGroupAlreadyExists",
  SecurityGroupHasMembers = "SecurityGroupHasMembers",
  DevicesForDeleteExist = "DevicesForDeleteExist",
  SecurityGroupNotFound = "SecurityGroupNotFound",
  NoItemFromBoxInShipment = "NoItemFromBoxInShipment",
  DocumentIsAlreadyInUse = "DocumentIsAlreadyInUse",
  TotalCannotBeLessThanZero = "TotalCannotBeLessThanZero",
  ShipmentEditReasonDuplicate = "ShipmentEditReasonDuplicate",
  NonControlModeShipment = "NonControlModeShipment",
  ShipmentIsPbo = "ShipmentIsPbo",
  InternalShipment = "InternalShipment",
  BlankHasDevicesWithBrand = "BlankHasDevicesWithBrand",
  BlankDoesNotHaveDevicesBrand = "BlankDoesNotHaveDevicesBrand",
  BlankDeviceAlreadyExist = "BlankDeviceAlreadyExist",
  PboShipmentExists = "PboShipmentExists",
  CounterpartyHasBlankLimit = "CounterpartyHasBlankLimit",
  CounterpartyAlreadyHasBlank = "CounterpartyAlreadyHasBlank",
  DevicesWereDeletedInCurrentPeriod = "DevicesWereDeletedInCurrentPeriod",
  NonCatalogPricelist = "NonCatalogPricelist",
  BlankAssignedToCounterparty = "BlankAssignedToCounterparty",
  BlankHasQuotas = "BlankHasQuotas",
  BlankHasDevices = "BlankHasDevices",
  GroupHasDevices = "GroupHasDevices",
  GroupHasQuotas = "GroupHasQuotas",
  GroupNameExist = "GroupNameExist",
  DevicesInBlanks = "DevicesInBlanks",
  DevicesAlreadyInPOSGroup = "DevicesAlreadyInPOSGroup",
  BossPasswordReset = "BossPasswordReset",
  DevicesFromDifferentDeviceSubgroups = "DevicesFromDifferentDeviceSubgroups",
  ShipmentRequestPOSNotFound = "ShipmentRequestPOSNotFound",
  WrongShipmentRequestCounterparty = "WrongShipmentRequestCounterparty",
  WrongShipmentRequestType = "WrongShipmentRequestType",
  BadOperation_ShipmentNotLocked = "BadOperation_ShipmentNotLocked",
  DevicesInDistributorBlank = "DevicesInDistributorBlank",
  NotAllOrdersAreClosed = "NotAllOrdersAreClosed",
  CounterpartyDoesnotHavePOSBlank = "CounterpartyDoesnotHavePOSBlank",
  DuplicatedRow = "DuplicatedRow",
  ShipmentsWithoutCurrency = "ShipmentsWithoutCurrency",
  ExceededApplicabilityLength = "ExceededApplicabilityLength",
  ExceededDescriptionLength = "ExceededDescriptionLength",
  ExceededCatalogNameLength = "ExceededCatalogNameLength",
  UserDoestNotHaveSubscription = "UserDoestNotHaveSubscription",
  UserSubscriptionAlreadyExists = "UserSubscriptionAlreadyExists",
  SubscriptionNotFound = "SubscriptionNotFound",
  ProformaClosed = "ProformaClosed",
  ProformaLocked = "ProformaLocked",
  LockedProformaFound = "LockedProformaFound",
  ProformaDeviceAlreadyExist = "ProformaDeviceAlreadyExist",
  ProformaNotLocked = "ProformaNotLocked",
  NoProformaItems = "NoProformaItems",
  NoItemFromBoxInProforma = "NoItemFromBoxInProforma"
}

export enum ShipmentControlLogStatuses {
  AllStatuses = 1,
  Sent = -1,
  NotSent = 0,
};

export enum PosDistributorOrderStatus {
  InProcess = "InProcess",
  Closed = "Closed",
  Submitted = "Submitted",
  Unknown = "Unknown"
};

export enum TypeOfCurrency {
  USD = "USD",
  EUR = "EUR"
}

export enum ProformaType {
  All = "",
  None = "None",
  Returnable = "Returnable",
  Closing = "Closing"
}

export enum ProformaStatus {
  Closed = "closed",
  Opened = "opened",
  All = "all",
};