const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export const USER_ACCESS_ENDPOINT = `${BASE_URL}/user-access`;
export const USERS_ALL_ENDPOINT = `${BASE_URL}/users`;
export const USER_SUBSCRIPTIONS_ENDPOINT = `${BASE_URL}/user-subscriptions`;

export const DASHBOARD_EXPIRING_LICENSES_ENDPOINT = `${BASE_URL}/dashboard/expiring-licenses-count`;
export const DASHBOARD_LICENSES_WITH_ENDING_POSITION_OUT_ENDPOINT = `${BASE_URL}/dashboard/licenses-with-ending-positions-count`;
export const DASHBOARD_EXPIRING_CONTRACTS_ENDPOINT = `${BASE_URL}/dashboard/expiring-contracts-count`;

export const CATEGORY_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/device/category-options`;
export const GROUP_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/device/group-options`;
export const PRODUCT_GROUP_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/device/product-group-options`;
export const COUNTERPARTY_OPTIONS_ENDPOINT = `${BASE_URL}/dictionary-counterparties/counterparty-options`;
export const OWNERS_DROPDOWN_OPTIONS_ENDPOINT = `${BASE_URL}/dictionary-counterparties/owner-options`;
export const SHIPMENT_REQUEST_ENDPOINT = `${BASE_URL}/form-values/shipment/shipment-request`;
export const COUNTERPARTY_REGIONS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/region-options`;
export const COUNTERPARTY_RESPONSIBLE_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/responsible-workers`;
export const COUNTERPARTY_PRICE_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/price-options`;
export const COUNTERPARTY_CURRENCY_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/currency-options`;
export const COUNTERPARTY_BLANK_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/blank-names`;
export const COUNTERPARTY_REPAIR_BLANK_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/repair-blank-names`;
export const COUNTERPARTY_POS_BLANK_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/pos-blank-names`;
export const COUNTERPARTY_AGREEMENT_TYPES_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/counterparty/agreement-type-options`;
export const COUNTERPARTY_WITH_LICENSE = `${BASE_URL}/form-values/licenses/counterparties-with-license`;
export const TYPE_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/licenses/type-options`;
export const CURRENCY_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/licenses/currency-options`;
export const CONTRACT_COUNTERPARTIES_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/contract/counterparties`;
export const CONTRACT_PRICELISTS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/contract/pricelists`;
export const CONTRACT_SIGNATURES_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/contract/signatures`;
export const RECEIVERS_OPTIONS_ENDPOINT = `${BASE_URL}/dictionary-counterparties/owner-options`;

export const PRICELIST_NAME_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/pricelist/name-options`;
export const PRICELIST_CONDITION_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/pricelist/condition-options`;
export const ORDER_REPAIR_SHIPMENTS_ENDPOINT = `${BASE_URL}/form-values/order-repair/order`;

export const PRICELIST_CURRENCY_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/pricelist/currency-options`;

export const DICTIONARY_DEVICES_LIST_ENDPOINT = `${BASE_URL}/dictionary-items/list`;
export const DICTIONARY_DEVICES_ENDPOINT = `${BASE_URL}/dictionary-items`;
export const DICTIONARY_DEVICES_ITEM_ENDPOINT = `${BASE_URL}/dictionary-items/item`;
export const DICTIONARY_DEVICES_UPLOAD_DEVICES_ENDPOINT = `${BASE_URL}/dictionary-items/upload-items`;

export const DICTIONARY_QUICK_DEVICES_LIST_ENDPOINT = `${BASE_URL}/dictionary-quick-devices/list`;
export const DICTIONARY_QUICK_DEVICES_SET_LICENSE_ENDPOINT = `${BASE_URL}/dictionary-quick-devices/set-license`;

export const DICTIONARY_ITEM_GROUPS_LIST_ENDPOINT = `${BASE_URL}/dictionary-item-groups/list`;
export const DICTIONARY_ITEM_GROUPS_ENDPOINT = `${BASE_URL}/dictionary-item-groups`;

export const DICTIONARY_ITEM_CATEGORIES_LIST_ENDPOINT = `${BASE_URL}/dictionary-item-categories/list`;
export const DICTIONARY_ITEM_CATEGORIES_ENDPOINT = `${BASE_URL}/dictionary-item-categories`;

export const DICTIONARY_COUTERPARTY_LIST_ENDPOINT = `${BASE_URL}/dictionary-counterparties/list`;
export const DICTIONARY_COUTERPARTY_TREE_ENDPOINT = `${BASE_URL}/dictionary-counterparties/tree`;
export const DICTIONARY_COUTERPARTY_GROUPS_ENDPOINT = `${BASE_URL}/dictionary-counterparties/groups`;
export const DICTIONARY_COUTERPARTY_ENTITIES_ENDPOINT = `${BASE_URL}/dictionary-counterparties/entities`;

export const DICTIONARY_QUICK_COUTERPARTY_LIST_ENDPOINT = `${BASE_URL}/dictionary-quick-counterparties/list`;
export const DICTIONARY_QUICK_COUTERPARTY_ENDPOINT = `${BASE_URL}/dictionary-quick-counterparties`;

export const DICTIONARY_ITEM_PRODUCT_GROUPS_LIST_ENDPOINT = `${BASE_URL}/dictionary-item-product-groups/list`;
export const DICTIONARY_ITEM_PRODUCT_GROUPS_ENDPOINT = `${BASE_URL}/dictionary-item-product-groups`;

export const DICTIONARY_ITEM_BRANDS_LIST_ENDPOINT = `${BASE_URL}/dictionary-item-brands/list`;
export const DICTIONARY_ITEM_BRANDS_ENDPOINT = `${BASE_URL}/dictionary-item-brands`;

export const DICTIONARY_TERM_OF_DELIVERY_LIST_ENDPOINT = `${BASE_URL}/dictionary-term-of-delivery/list`;
export const DICTIONARY_TERM_OF_DELIVERY_ENDPOINT = `${BASE_URL}/dictionary-term-of-delivery`;

export const DICTIONARY_COUNTRIES_LIST_ENDPOINT = `${BASE_URL}/dictionary-countries/list`;
export const DICTIONARY_COUNTRIES_ENDPOINT = `${BASE_URL}/dictionary-countries`;
export const DICTIONARY_COUNTRIES_ITEM_ENDPOINT = `${BASE_URL}/dictionary-countries/item`;

export const DICTIONARY_CURRENCY_LIST_ENDPOINT = `${BASE_URL}/dictionary-currency/list`;
export const DICTIONARY_CURRENCY_ENDPOINT = `${BASE_URL}/dictionary-currency`;

export const DICTIONARY_PRICELISTS_LIST_ENDPOINT = `${BASE_URL}/dictionary-pricelist/list`;
export const DICTIONARY_PRICELISTS_ENDPOINT = `${BASE_URL}/dictionary-pricelist`;

export const DICTIONARY_PRICELIST_NAMES_LIST_ENDPOINT = `${BASE_URL}/dictionary-pricelist-names/list`;
export const DICTIONARY_PRICELIST_NAMES_ENDPOINT = `${BASE_URL}/dictionary-pricelist-names`;

export const DICTIONARY_SIGNATURES_LIST_ENDPOINT = `${BASE_URL}/dictionary-signatures/list`;
export const DICTIONARY_SIGNATURES_ENDPOINT = `${BASE_URL}/dictionary-signatures`;

export const DICTIONARY_EXCHANGE_RATES_ENDPOINT = `${BASE_URL}/dictionary-exchange-rates`;

export const DICTIONARY_RESPONSIBLE_WORKERS_LIST_ENDPOINT = `${BASE_URL}/dictionary-responsible-worker/list`;
export const DICTIONARY_RESPONSIBLE_WORKERS_ENDPOINT = `${BASE_URL}/dictionary-responsible-worker`;

export const DICTIONARY_CUSTOMS_CODES_LIST_ENDPOINT = `${BASE_URL}/dictionary-customs-codes/list`;
export const DICTIONARY_CUSTOMS_CODES_ENDPOINT = `${BASE_URL}/dictionary-customs-codes`;

export const DICTIONARY_CUSTOMS_CODES_INTRASTAT_LIST_ENDPOINT = `${BASE_URL}/dictionary-customs-codes-intrastat/list`;
export const DICTIONARY_CUSTOMS_CODES_INTRASTAT_ENDPOINT = `${BASE_URL}/dictionary-customs-codes-intrastat`;

export const DICTIONARY_LICENSE_LISTS_ENDPOINT = `${BASE_URL}/dictionary-license-lists`;
export const DICTIONARY_LICENSE_LISTS_LIST_ENDPOINT = `${BASE_URL}/dictionary-license-lists/list`;

export const DICTIONARY_FORECAST_PRICES_LIST_ENDPOINT = `${BASE_URL}/dictionary-forecast-prices/list`;
export const DICTIONARY_FORECAST_PRICES_ENDPOINT = `${BASE_URL}/dictionary-forecast-prices`;

export const DICTIONARY_CERTIFIED_DEVICES_LIST_ENDPOINT = `${BASE_URL}/dictionary-certified-devices/list`;
export const DICTIONARY_CERTIFIED_DEVICES_ENDPOINT = `${BASE_URL}/dictionary-certified-devices`;

export const LICENSES_LIST_ENDPOINT = `${BASE_URL}/op-licenses/list`;
export const LICENSES_ENDPOINT = `${BASE_URL}/op-licenses`;
export const FIND_LICENSES_ENDPOINT = `${BASE_URL}/op-licenses/get-licenses-with-sek`;

export const REX_DEVICES_ENDPOINT = `${BASE_URL}/op-rex`;

export const CONTRACTS_LIST_ENDPOINT = `${BASE_URL}/op-contracts/list`;
export const CONTRACTS_ENDPOINT = `${BASE_URL}/op-contracts`;

export const SHIPMENTS_LIST_ENDPOINT = `${BASE_URL}/op-shipments/list`;
export const SHIPMENTS_ENDPOINT = `${BASE_URL}/op-shipments`;
export const SHIPMENTS_AUTOCOMPLETE_ENDPOINT = `${BASE_URL}/op-shipments/autocomplete-shipment-notes`;
export const SHIPMENTS_ITEMS_AUTOCOMPLETE_ENDPOINT = `${BASE_URL}/op-shipments/autocomplete-shipment-item-notes`;
export const SHIPMENTS_ITEM_ENDPOINT = `${BASE_URL}/op-shipments/item`;
export const SHIPMENTS_EDIT_DATE_ENDPOINT = `${BASE_URL}/op-shipments/edit-date`;

export const SHIPMENTS_CONTROL_ENDPOINT = `${BASE_URL}/op-shipments/control`;
export const SHIPMENTS_CONTROL_EDIT_REASON_ENDPOINT = `${BASE_URL}/op-shipments/control/edit-reason`;
export const SHIPMENTS_CONTROL_SHIPMENTS_FOR_TRANSFER_ENDPOINT = `${BASE_URL}/op-shipments/control/shipments-for-transter`;
export const SHIPMENTS_CONTROL_EDIT_DATE_ENDPOINT = `${BASE_URL}/op-shipments/control/edit-date`;

export const BLANKS_LIST_ENDPOINT = `${BASE_URL}/op-blanks/list`;
export const BLANKS_ENDPOINT = `${BASE_URL}/op-blanks`;
export const BLANKS_SET_PERIOD_ENDPOINT = `${BASE_URL}/op-blanks/order-form/set-period`;
export const BLANKS_GET_PERIOD_ENDPOINT = `${BASE_URL}/op-blanks/order-form/get-period`;
export const DICTIONARY_MODIFICATIONS_LIST = `${BASE_URL}/dictionary-modifications/list`;
export const ORDER_REGION_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/blank/region-options`;
export const BLANKS_CLEAR_STATUSES_ENDPOINT = `${BASE_URL}/op-blanks/items/clear-statuses`;
export const BLANKS_PRICELISTS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/blank/pricelists`;
export const FORM_VALUES_BLANK = `${BASE_URL}/form-values/blank`;

export const BLANKS_REPAIR_LIST_ENDPOINT = `${BASE_URL}/op-blanks-repair/list`
export const BLANKS_REPAIR_ENDPOINT = `${BASE_URL}/op-blanks-repair`
export const FORM_VALUES_BLANK_REPAIR = `${BASE_URL}/form-values/blank-repair`;
export const FORM_VALUES_BLANK_POS = `${BASE_URL}/form-values/blank-pos`;
export const BLANKS_REPAIR_PRICELISTS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/blank-repair/pricelists`;
export const BLANKS_REPAIR_ITEMS_AUTOCOMPLETE_ENDPOINT = `${BASE_URL}/op-blanks-repair/suggestions/applicability`;

export const BLANKS_ADDITIONS_ENDPOINT = `${BASE_URL}/op-blanks-addition`;
export const BLANKS_ADDITIONS_ITEMS_ENDPOINT = `${BASE_URL}/op-blanks-addition/items`;

export const BLANKS_POS_ENDPOINT = `${BASE_URL}/op-blanks-pos`;

export const ORDERS_LIST_ENDPOINT = `${BASE_URL}/op-orders/list`;
export const ORDERS_ENDPOINT = `${BASE_URL}/op-orders`;
export const ORDERS_CLOSE_ENDPOINT = `${BASE_URL}/op-orders/close`;
export const ORDERS_SEND_TO_WORK_ENDPOINT = `${BASE_URL}/op-orders/send-to-work`;
export const ORDERS_BYUER_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/order/counterparty-options`;
export const ORDERS_COMPANIES_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/order/companies`;
export const ORDER_DEBTS_ENDPOINT = `${BASE_URL}/op-orders/order-debts`;
export const ORDER_ACCEPT_ENDPOINT = `${BASE_URL}/op-orders/accept`;
export const SHIPMENTS_FOR_ORDER_ENDPOINT = `${BASE_URL}/form-values/order/order`;
export const SHIPMENTS_FOR_POS_ORDER_ENDPOINT = `${BASE_URL}/form-values/order-pos/order`;

export const ORDERS_REPAIR_LIST_ENDPOINT = `${BASE_URL}/op-orders-repair/list`;
export const ORDERS_REPAIR_ENDPOINT = `${BASE_URL}/op-orders-repair`;
export const ORDERS_REPAIR_DEBTS_ENDPOINT = `${BASE_URL}/op-orders-repair/order-debts`;

export const PAYMENTS_LIST_ENDPOINT = `${BASE_URL}/op-payments/list`;
export const PAYMENTS_SHIPMENTS_ENDPOINT = `${BASE_URL}/op-payments/shipments`;
export const PAYMENTS_ENDPOINT = `${BASE_URL}/op-payments`;

export const SHIPMENT_REQUESTS_LIST_ENDPOINT = `${BASE_URL}/op-shipment-requests/list`;
export const SHIPMENT_REQUESTS_ENDPOINT = `${BASE_URL}/op-shipment-requests`;

export const PRICE_CALCULATION_LIST_ENDPOINT = `${BASE_URL}/pf-price-calculations/list`;
export const PRICE_CALCULATION_ENDPOINT = `${BASE_URL}/pf-price-calculations`;
export const PRICE_CALCULATION_PRICELIST_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/price-calculation/available-price-lists`;
export const PRICE_CALCULATION_APPROVERS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/price-calculation/approvers`;

export const PRICE_FORMATION_GROUPS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/price-formation/series-groups`;
export const PRICE_FORMATION_PRICELISTS_ENDPOINT = `${BASE_URL}/form-values/price-formation/pricelists`;
export const PRICE_FORMATION_DEPARTMENTS_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/price-formation/departments`;
export const PRICE_FORMATION_EXPORT_DEVICES_ENDPOINT = `${BASE_URL}/pf-price-formation/export-devices-excel-new`;
export const PRICE_FORMATION_COMPANIES_OPTIONS_ENDPOINT = `${BASE_URL}/form-values/price-formation/companies`;

export const ONE_TIME_PRICES_LIST_ENDPOINT = `${BASE_URL}/pf-oneTimePrices/list`;
export const ONE_TIME_PRICES_ARCHIVE_LIST_ENDPOINT = `${BASE_URL}/pf-oneTimePrices/archive`;
export const ONE_TIME_PRICES_DELETE_ENDPOINT = `${BASE_URL}/pf-oneTimePrices/delete`;

export const ENTERPRISE_PROFITABILITY_ENDPOINT = `${BASE_URL}/pf-enterprise-profitability`;

export const REPORTS_YUKON_LICENSE_EU001_ENDPOINT = `${BASE_URL}/reports-yukon-shipments-with-license`;
export const REPORTS_ABC_ANALYSIS_ENDPOINT = `${BASE_URL}/reports-abc-analysis`;
export const REPORTS_ASSORTIMENT_ANALYSIS_ENDPOINT = `${BASE_URL}/reports-assortment-analysis`;
export const REPORTS_CUSTOMER_CARD_ENDPOINT = `${BASE_URL}/reports-customer-card`;
export const REPORTS_CUSTOMER_DEBT_ENDPOINT = `${BASE_URL}/reports-customer-debt`;
export const REPORTS_DEPT_PAYMENTS_ENDPOINT = `${BASE_URL}/reports-dept-payments`;
export const REPORTS_ORDER_FULFILLMENT_ENDPOINT = `${BASE_URL}/reports-order-fulfillment`;
export const REPORTS_ORDER_FULFILLMENT_FOR_PAYER_ENDPOINT = `${BASE_URL}/reports-order-fulfillment-for-payer`;
export const REPORTS_QUOTAS_ENDPOINT = `${BASE_URL}/reports-quotas`;
export const REPORTS_RESPONSIBLE_WORKER_ENDPOINT = `${BASE_URL}/reports-responsible-worker`;
export const REPORTS_SERIAL_NUMBER_ENDPOINT = `${BASE_URL}/reports-serial-number`;
export const REPORTS_SHIPMENT_DETAILED_ENDPOINT = `${BASE_URL}/reports-shipment-detailed`;
export const REPORTS_SHIPMENT_LOCATION_ENDPOINT = `${BASE_URL}/reports-shipment-location`;
export const REPORTS_SHIPMENT_PERIOD_FORECAST_ENDPOINT = `${BASE_URL}/reports-shipment-period-forecast`;
export const REPORTS_SHIPMENT_SCHEDULE_ENDPOINT = `${BASE_URL}/reports-shipment-schedule`;
export const REPORTS_TARGET_INDICATORS_ENDPOINT = `${BASE_URL}/reports-target-indicators`;
export const REPORTS_YUKON_SHIPMENTS_ENDPOINT = `${BASE_URL}/reports-yukon-shipments`;
export const REPORTS_YUKON_SHIPPED_LICENSES_EXCEL_ENDPOINT = `${BASE_URL}/reports-yukon-shipped-licenses/report/export-excel`;
export const REPORTS_INTERNAL_PRICES_ENDPOINT = `${BASE_URL}/reports-internal-prices`;
export const REPORTS_STATISTICS_BUYERS_DINAMIC_SALES_ENDPOINT = `${BASE_URL}/reports-statistics-buyers-dynamic-sales`;
export const REPORTS_SHIPMENT_CUSTOMER_ENDPOINT = `${BASE_URL}/reports-shipment-customer`;
export const REPORTS_YUKON_INTRASTAT_ENDPOINT = `${BASE_URL}/reports-yukon-intrastat`;
export const REPORTS_SHIPMENT_AMOUNTS_OF_NEW_PRODUCTS_ENDPOINT = `${BASE_URL}/reports-shipment-amounts-of-new-products`;
export const REPORTS_INTERNAL_SHIPMENTS_ENDPOINT = `${BASE_URL}/reports-internal-shipments`;
export const REPORTS_FORECAST_ENDPOINT = `${BASE_URL}/reports-forecast`;
export const REPORTS_LICENSE_DEVICES_ENDPOINT = `${BASE_URL}/reports-license-devices`;
export const REPORTS_PULSAR_INTRASTAT_ENDPOINT = `${BASE_URL}/reports-pulsar-intrastat`;
export const REPORTS_POLARIS_ACCOUNTING_ENDPOINT = `${BASE_URL}/reports-polaris-accounting-report/export-excel`;
export const REPORTS_POLARIS_PURCHASE_ENDPOINT = `${BASE_URL}/reports-polaris-purchasing-report/export-excel`;
export const REPORTS_MARKUP_ENDPOINT = `${BASE_URL}/reports-markup`;
export const REPORTS_DEVICE_SALES_BY_MONTH = `${BASE_URL}/reports-device-sales-by-month`;

export const DISTRIBUTOR_BOSSES_ENDPOINT = `${BASE_URL}/utils-distributor-bosses`;
export const NOTIFICATIONS_LIST = `${BASE_URL}/utils-emails-log/list`;
export const HISTORY_SHIPMENTS_ENDPOINT = `${BASE_URL}/utils-audit-log/shipments`;
export const HISTORY_DEVICES_ENDPOINT = `${BASE_URL}/utils-audit-log/devices`;
export const HISTORY_COUNTERPARTIES_ENDPOINT = `${BASE_URL}/utils-audit-log/counterparties`;
export const HISTORY_CUSTOM_CODES_INTRASTAT_ENDPOINT = `${BASE_URL}/utils-audit-log/custom-codes-intrastat`;
export const SHIPMENTS_EVENTS_ENDPOINT = `${BASE_URL}/utils-audit-log/shipments/form-values/events`;
export const SHIPMENT_SCHEDULE_LOADING_ENDPOINT = `${BASE_URL}/utils-shipment-schedule-loading/upload-shipments`;
export const PRICES_LOADING_ENDPOINT = `${BASE_URL}/utils-price-loading/upload-prices`;
export const PRICES_LOADING_APPLY_FOR_CONFIRMATION_ENDPOINT = `${BASE_URL}/utils-price-loading/apply-for-confirmation`;
export const PRICES_LOADING_APPLY_CONFIRMED_ENDPOINT = `${BASE_URL}/utils-price-loading/apply-confirmed`;
export const PRICE_CHANGE_NOTIFICATIONS_LIST_ENDPOINT = `${BASE_URL}/utils-price-changes/list`;
export const PRICE_CHANGE_NOTIFICATIONS_NOTIFY_ENDPOINT = `${BASE_URL}/utils-price-changes/notify`;
export const CONTROL_SHIPMENT_LOG_ENDPOINT = `${BASE_URL}/utils-control-shipment-log`;
export const HEALTHCHECK_ENDPOINT = `${process.env.REACT_APP_API_URL}/hc-ui#/healthchecks`;

export const FAQ_ENDPOINT = `${BASE_URL}/utils-faq`;

export const PROFORMAS_LIST_ENDPOINT = `${BASE_URL}/prof-proformas/list`;
export const PROFORMAS_ENDPOINT = `${BASE_URL}/prof-proformas`;
export const PROFORMAS_AUTOCOMPLETE_ENDPOINT = `${BASE_URL}/prof-proformas/autocomplete-proforma-notes`;
export const PROFORMAS_ITEMS_AUTOCOMPLETE_ENDPOINT = `${BASE_URL}/prof-proformas/autocomplete-proforma-item-notes`;